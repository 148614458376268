import axios from "./axios";
import { Trimble } from "./trimble";

export const Drive = {
  copy: async function (params) {
    const res = await axios.post("/api/drive/copy", params);
    return res.data;
  },
  create: async function (params) {
    const res = await axios.post("/api/drive/create", params);
    return res.data;
  },
  delete: async function (params) {
    const res = await axios.post("/api/drive/delete", params);
    return res.data;
  },
  export: async function (params) {
    const res = await axios.post("/api/drive/export", params);
    return res.data;
  },
  download: async function (params) {
    const res = await axios.post("/api/drive/download", params);
    return res.data;
  },
  get: async function (params) {
    const res = await axios.post("/api/drive/get", params);
    return res.data;
  },
  list: async function (params) {
    const res = await axios.post("/api/drive/list", params);
    return res.data;
  },
  update: async function (params) {
    const res = await axios.post("/api/drive/update", params);
    return res.data;
  },
  copyFolder: async function (params) {
    const res = await axios.post("/api/drive/copyFolder", params);
    return res.data;
  },
  share: async function (params) {
    const res = await axios.post("/api/drive/share", params);
    return res.data;
  },
  shareFolder: async function ({ folderId, emailAddress, editAccess, sendNotificationEmail = false }) {
    const params = {
      fileId: folderId,
      sendNotificationEmail,
      requestBody: {
        type: "user",
        role: editAccess ? "writer" : "reader",
        emailAddress,
      },
      fields: "id",
    };
    const res = await this.share(params);
    return res.data;
  },
  shareFolderPublic: async function (folderId) {
    const params = {
      fileId: folderId,
      requestBody: {
        role: "reader",
        type: "anyone",
        allowFileDiscovery: false,
      },
      fields: "id",
    };
    const res = await this.share(params);
    return res.data;
  },
  shareFile: async function ({ fileId, emailAddress, editAccess, sendNotificationEmail }) {
    const res = await this.shareFolder({ fileId, emailAddress, editAccess, sendNotificationEmail });
    return res;
  },
  getFolders: async function ({ name, parent, options = {} }) {
    options.limit = options.limit || 1000;
    const folders = [];
    let pageToken = null;
    const queries = [`mimeType='${this.getMimeType("folder")}'`];
    if (parent) {
      const parentId = typeof parent === "string" ? parent : parent.id;
      queries.push(`'${parentId}' in parents`);
    }
    if (name) queries.push(`name='${name}'`);

    let fields = "id,name,mimeType,trashed,parents,webViewLink";
    if (options.fields) fields += "," + options.fields;

    const listOptions = {
      q: queries.join(" and "),
      fields: `files(${fields})`,
      pageSize: options.limit,
    };
    while (true) {
      console.log("Getting folders...");
      if (pageToken) listOptions.pageToken = pageToken;
      let response = await this.list(listOptions);
      if (!response.files || !response.files.length) return folders;
      for (let folder of response.files) {
        if (folder.trashed) continue;
        delete folder.trashed;
        folders.push(folder);
        if (options.limit && folders.length >= options.limit) return folders;
      }
      pageToken = response.nextPageToken;
      if (!pageToken) return folders;
    }
  },
  /**
   * lists files under a folder
   */
  getFilesByFolder: async function (parent, name, limit, fields) {
    limit = limit || 1000;
    fields = fields || "id,name,mimeType,parents,thumbnailLink,webViewLink,webContentLink";
    let files = [];
    let parentId = typeof parent === "object" ? parent.id : parent;
    let pageToken = null;
    let q = `'${parentId}' in parents and trashed = false`;
    if (name) q += `and name contains '${name}'`;
    let listOptions = { q, fields: `files(${fields})` };
    while (true) {
      console.log("Getting files by folder...");
      if (pageToken) listOptions.pageToken = pageToken;
      let response = await this.list(listOptions);
      if (!response.files || !response.files.length) return files;
      for (let file of response.files) {
        files.push(file);
        if (files.length >= limit) return files;
      }
      pageToken = response.nextPageToken;
      if (!pageToken) return files;
    }
  },
  getFolder: async function ({ id, name, parent, fields = "id,name,parents,webViewLink" }) {
    if (id) return await this.get({ fileId: id, fields });
    const folders = await this.getFolders({ name, parent, options: { fields } });
    return folders.length ? folders[0] : null;
  },
  deleteFile: async function (fileId) {
    const res = await axios.post("/api/drive/delete", { fileId });
    return res.data;
  },
  deleteFolder: async function (folderId) {
    const res = await axios.post("/api/drive/delete", { fileId: folderId });
    return res.data;
  },
  deleteFileByName: async function (name, parentFolder) {
    const file = await this.getFileByName(name, null, parentFolder);
    if (!file) return null;
    const res = await this.deleteFile(file.id);
    return res;
  },
  /**
   *
   * @param {*} file - can be either a file or folder
   * @param {*} isTrashed
   * @returns
   */
  setTrashed: async function (file, isTrashed = true) {
    const fileId = typeof file === "string" ? file : file.id;
    const res = await this.update({ fileId, resource: { trashed: isTrashed } });
    return res;
  },

  /**
   * create a file (mimeType: doc | sheet)
   * optionally, within another folder.
   *
   */
  createFile: async function (name, mimeType, body, parentFolder = "") {
    mimeType = this.getMimeType(mimeType);
    const resource = { name, mimeType };
    let media;
    if (body) media = { body, mimeType };
    const parentFolderId = typeof parentFolder === "string" ? parentFolder : parentFolder.id;
    if (parentFolderId) resource.parents = [parentFolderId];
    const fields = "id,name,mimeType,thumbnailLink";
    const response = await this.create({ fields, resource, media });
    return response && response;
  },
  updateFile: async function (fileId, mimeType, body) {
    mimeType = this.getMimeType(mimeType);
    const resource = { mimeType };
    const media = body ? { body, mimeType } : undefined;
    const response = await this.update({ fileId, resource, media });
    return response && response;
  },
  upsertFile: async function (name, mimeType, body, parentFolder = "") {
    let file = await this.getFileByName(name, mimeType, parentFolder);
    if (!file) file = await this.createFile(name, mimeType, body, parentFolder);
    else file = await this.updateFile(file.id, mimeType, body);
    return file;
  },
  createFolder: async function (name, parentFolder = "") {
    const mimeType = "application/vnd.google-apps.folder";
    const resource = { name, mimeType };
    const parentFolderId = typeof parentFolder === "string" ? parentFolder : parentFolder.id;
    if (parentFolderId) resource.parents = [parentFolderId];
    const response = await this.create({ resource });
    return response;
  },

  /**
   * move file to a folder
   * this also removes the file from all other folders
   */
  move: async function (fileId, folderId, fields) {
    const response = await this.get({ fileId, fields: fields || "id,name,parents" });
    let updateOptions = { fileId, addParents: folderId };
    if (response.parents) {
      updateOptions["removeParents"] = response.parents.join(",");
    }
    await this.update(updateOptions);
    return true;
  },

  /**
   * copy file
   * @returns file object with id property
   */
  copyFile: async function (fileId, name, folderId, mimeType) {
    let requestBody = { name };
    if (mimeType) requestBody.mimeType = mimeType;
    if (folderId) requestBody.parents = [folderId];
    const response = await this.copy({ fileId, requestBody });
    return response && response;
  },
  getMimeType: function (type) {
    const mimeTypes = {
      folder: "application/vnd.google-apps.folder",
      doc: "application/vnd.google-apps.document",
      document: "application/vnd.google-apps.document",
      sheet: "application/vnd.google-apps.spreadsheet",
      spreadsheet: "application/vnd.google-apps.spreadsheet",
    };
    return mimeTypes[type] || type;
  },

  /**
   * get file info by its id
   */
  getFileById: async function (fileId, fields) {
    fields = fields || "kind,id,name,mimeType,trashed,parents,webViewLink,webContentLink";
    let response = await this.get({ fileId, fields });
    return response;
  },

  /**
   * get file info by its name, optionally by its type and parent folder id
   */
  getFileByName: async function (name, type, folderId) {
    let queries = [];
    queries.push(`name = '${name}'`);
    if (folderId) queries.push(`'${folderId}' in parents`);
    if (type) {
      let mimeType = this.getMimeType(type);
      queries.push(`mimeType = '${mimeType}'`);
    }
    let q = queries.join(" and ");
    let fields = "files(id,name,mimeType,trashed,parents,webViewLink,webContentLink)";
    let response = await this.list({ q, fields });
    if (!response.files || !response.files.length) return null;
    // @TODO: return latest modified
    return response.files[0];
  },

  readFile: async function (fileId) {
    let file = await this.get({ fileId, alt: "media" });
    return file;
  },
  /**
   * Download the file data from Google Drive
   * @param {*} fileId
   * @returns base64 encoded file
   */
  downloadFile: async function (fileId) {
    let res = await this.download({ fileId });
    return res.data;
  },
  copyFileToTrimble: async function (fileId, trimbleFolderId) {
    const file = await this.getFileById(fileId, "name");
    const name = file.name;
    const base64 = await this.downloadFile(fileId);
    const { uploadURL, uploadId } = await Trimble.getUploadUrl(name, trimbleFolderId);
    const res = await Trimble.uploadFile(uploadURL, base64);
    if (res.error) return res;
    else {
      const res = await Trimble.commitFileUpload(uploadId);
      return res;
    }
  },
};
