import axios from "axios";
import { Drive } from "../google";
import _ from "lodash";
import * as cheerio from "cheerio";
import store from "../../store";
import { SET_PARAMS } from "../types";
import { chunk } from "../../utils/general";

export function getMozaikProductParam(product, param) {
  const el = product.find(`CabProdParm[Name=${param}]`);
  return el.length ? el.attr("Value") : null;
}

export function getMozaikLibraryParam(library, param) {
  let params = [];
  // could this be a stack overflow?
  while (!params.length) {
    console.log("Waiting for params to be loaded...");
    const state = store.getState();
    params = state.room.params;
  }
  const match = params.find(p => p.library === library && p.name == param);
  return match ? match.value : null;
}

export function getMozaikParam(product, param) {
  // check for product-level parameter override

  const productParam = getMozaikProductParam(product, param);
  if (productParam) return productParam;

  // otherwise default to the library param
  const library = product.attr("SourceLib");
  const libraryParam = getMozaikLibraryParam(library, param);
  return libraryParam;
}

/**
 * Thus function is run whenever a job is selected and will parse the param files for each library
 * and store it in state for later
 */
export const getMozaikLibraryParams = jobFolderId => async dispatch => {
  const params = [];
  const files = await Drive.getFilesByFolder(jobFolderId);

  // Job Library Parameters
  const paramFiles = files.filter(file => file.name.endsWith("JobParms.dat"));
  const filesContent = await Promise.all(paramFiles.map(file => Drive.readFile(file.id))); // fetch in parallel to improve perf
  console.log({ paramFiles, filesContent });
  const filesWithContent = paramFiles.map((file, i) => ({ ...file, content: filesContent[i] }));
  for (const file of filesWithContent) {
    const library = file.name.replace("-JobParms.dat", "");
    const content = file.content;
    const start = content.indexOf("DoorOL");
    const arr = content.slice(start).split("\r\n");
    const chunked = chunk(arr, 17);
    chunked.forEach(([name, description, value]) => {
      params.push({ name, description, value, library });
    });
  }

  dispatch({ type: SET_PARAMS, payload: params });
  console.log("Params added to state...");
  return params;
};

export const getMozaikCustomParameters = jobFolderId => async dispatch => {
  const params = [];
  const files = await Drive.getFilesByFolder(jobFolderId);
  const customParamFiles = files.filter(file => file.name.endsWith("JobCusParms.dat"));
  for (const file of customParamFiles) {
    const library = file.name.split("-")[0];
    const content = await Drive.readFile(file.id);
    const xml = content.slice(content.indexOf("<?xml"));
    const $ = cheerio.load(xml, { xmlMode: true });
    $("CustomParameter")
      .toArray()
      .forEach(CustomParameter =>
        params.push({
          name: CustomParameter.attrib("Name"),
          description: CustomParameter.attrib("description"),
          library,
          value: null,
        })
      );
  }
};
